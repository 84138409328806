import * as client_hooks from '../../src/hooks.client.js';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')];

export const server_loads = [0,2];

export const dictionary = {
	"/": [3],
	"/app": [~4,[2]],
	"/app/agenda": [~5,[2]],
	"/document/[doc_type]/[doc_id]": [~6],
	"/herstel-wachtwoord": [7],
	"/herstel-wachtwoord/[uid]/[token]": [8],
	"/login": [~9],
	"/logout": [~10],
	"/over-ons": [11],
	"/privacy-policy": [12],
	"/registreer": [~13],
	"/valideer/[token]": [14]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};