import * as Sentry from "@sentry/sveltekit";
import {SENTRY_DSN} from '$lib/svelte_1848_settings.js';

// via https://docs.sentry.io/platforms/javascript/guides/sveltekit/manual-setup/
Sentry.init({
  dsn: "",
  dsn: SENTRY_DSN,
});

const myErrorHandler = ({ error, event }) => {
  console.error("An error occurred on the client side:", error, event);
};

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
